@use "sass:math";

$primary: rgb(252, 222,17);
$info: rgb(136, 16, 166);
$link: rgb(32, 54, 172);
$success: rgb(104, 208, 104);
$warning: rgb(255, 114, 0);
$danger: rgb(227, 84, 84);
$dark: rgb(54,54,54);
$light: rgb(248,249,250);

$theme-colors: (
  "primary":    $primary,
  "secondary":  $info,
  "success":    $success,
  "info":       $link,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
);

$nav-width: 250px;
$scrollbar-width: 0.4rem;
$view-offset-right: 2.8rem;

$spacer: 1rem;
$spacers: (
  0: 0,
  1: math.div($spacer, 4),
  2: math.div($spacer, 2),
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4,
  7: $spacer * 5,
  8: $spacer * 6
);

$yw-breakpoints: (
  "sm":   600px,
  "md":   768px,
  "lg":   992px,
  "xlg":  1200px,
  "xxlg": 1408px,
);
