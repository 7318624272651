@import "variables";
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "material-theme";
@import '../../node_modules/animate.css/animate';
@import "mixins";

/* Responsive Rules */
@import "responsive/xs-devices";
@import "responsive/sm-devices";
@import "responsive/md-devices";
@import "responsive/lg-devices";
@import "responsive/xlg-devices";
@import "responsive/xxlg-devices";

