@import "variables";

@mixin custom-scrollbars($size: 10px, $foreground-color: $primary, $background-color: mix($foreground-color, white, 25%)) {
  &::-webkit-scrollbar {
    width:  $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  & {
    scrollbar-color: $foreground-color $background-color;
    scrollbar-width: thin;
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}
