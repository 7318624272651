@use "sass:map";
@import "scss-styles/variables";

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: map.get($yw-breakpoints, "md")) and (orientation: landscape), only screen and (min-height: map.get($yw-breakpoints, "md")) and (orientation: portrait) {
  nav {
    padding: 0 3rem;
  }

  #modal {
    .modal-content {
      max-height: 70%;
    }
  }
}
