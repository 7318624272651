@import "scss-styles/imports";

html { overflow: auto; width: calc(100vw - #{$scrollbar-width} - 0.1rem); margin: 0; padding: 0; border: 0 }
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

html {
  @include custom-scrollbars(0);
}

.month-datepicker {
  .mat-calendar-controls button:first-child {
    display: none;
  }
}

.scrollable-container {
  overflow: auto;
  width: 100%;
  @include custom-scrollbars($scrollbar-width);
  margin-bottom: 1rem;
}

.loading-wrapper {
  height: 100vh;
  width: 100vw;
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  .loading-content {
    width: 150px;
    height: 120px;
    background-image: url("/assets/images/loader.png");
    background-size: cover;
    background-repeat: no-repeat;
    animation-name: spinner;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-duration: 1.5s;
  }
}

.is-full-width {
  width: 100%;
}

.is-margin-auto {
  margin: auto;
}

@keyframes spinner {
	0% {
		transform: rotateY(0deg);
	}
	50% {
		transform: rotateY(90deg);
	}
	100% {
		transform: rotateY(0deg);
	}
}
